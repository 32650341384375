<template>
  <div>
    <h2 class='text-xl font-semibold'>
      Authors
    </h2>
    <div class='text-gray-500 text-sm font-normal normal-case mb-4'>Drag-and-drop to re-order authors.</div>
    <table class='w-full text-xs'>
      <thead>
        <tr class='text-sm text-gray-600'>
          <th class='text-left px-2 py-2 w-10'>#</th>
          <th class='text-left px-2 py-2'>Name</th>
          <th class='text-left px-2 py-2'>Contact</th>
          <th class='text-left px-2 py-2'>Affiliation</th>
          <th class='text-right pl-2 py-2'></th>
        </tr>
      </thead>
      <draggable v-model='authorList' tag='tbody'>
        <tr v-for='author in authorList'
          :key='`author-${author.authorIndex}`'
          class='border-t cursor-move hover:bg-gray-100'>
           <td class='text-left px-2 py-2'>{{author.authorIndex}}</td>
            <td class='text-left px-2 py-2'>
              <div>{{author.firstName}} {{author.lastName}}</div>
              <div>({{author.phoneNumber}})</div>
            </td>
            <td class='text-left px-2 py-2'>{{author.email}}</td>
            <td class='text-left px-2 py-2'>
              <div v-for='affiliation in author.affiliations'
                :key='`affiliation-${authorBackground(affiliation)}`'
                class='mb-1'>
                {{authorBackground(affiliation)}}
              </div>
            </td>
            <td class='text-left px-2 py-2 text-xs'>
              <div v-if='author.isPresenter'>Presenter</div>
              <div v-if='author.isCorrespondant' class='whitespace-nowrap'>Corresponding Author</div>
            </td>
            <td class='text-right pl-2 py-2 flex flex-row justify-end items-center gap-x-1'>
              <button
                @click='editAuthor(author)'
                class='hover:bg-gray-200 p-1 rounded-md'>
                <edit-icon class='h-4 ' />
              </button>
              <button
                @click='removeAuthor(author)'
                class='hover:bg-gray-200 p-1 rounded-md'>
                <x-icon class='h-4'/>
              </button>
            </td>
          </tr>
      </draggable>
    </table>
    <div>
      <button
        @click='addAuthor'
        class='border border-solid border-gray-200 hover:border-gray-500 py-2 px-16 text-center text-white text-sm uppercase block rounded-md hover:shadow-md'
        style='background-color:var(--eventMainColor);'>        
        <plus-icon class='inline-block h-4' /> Add Author
      </button>
    </div>
  </div>
</template>

<script>
import { XIcon, EditIcon, PlusIcon } from 'vue-tabler-icons'
import { mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import draggable from 'vuedraggable'

export default {
  name: 'AbstractSubmissionAuthors',
  components: {
    draggable,
    EditIcon,
    XIcon,
    PlusIcon,
  },
  computed: {
    ...mapFields('abstracts', [
      'editingAbstractSubmission'
    ]),
    authorList: {
      get () {
        return this.editingAbstractSubmission.authors
      },
      set (newList) {
        this.updateAuthors(newList)
      }
    },
  },
  methods: {
    ...mapActions('abstracts', [
      'resetEditingAuthor',
    ]),
    ...mapMutations('abstracts', [
      'updateAuthors',
      'removeAuthor',
      'setEditingAuthor',
    ]),
    authorBackground (affiliation) {
      return `${affiliation.department}, ${affiliation.affiliation}, ${affiliation.country}`
    },
    addAuthor () {
      this.resetEditingAuthor()
      this.$modal.show('edit-author')
    },
    editAuthor (author) {
      this.setEditingAuthor(author)
      this.$modal.show('edit-author')
    },
  },
}
</script>
